<template>
  <v-card class="rd elevation-0" >
    <img class="img" :src="src"  width="100%" max-height="100%" @click="imgClick" style="cursor: pointer" />

    <span class="name">{{ pageValue.name || "No Name" }}</span>
    <span class="num">{{ pageValue.nftCount || 0 }} </span>
    <div class="avatar" v-if="!isMobile">
       <Avatar  :size="42" :did="pageValue.owner" :hash="pageValue.creatorPhoto" showAvatarInfo/>
    </div>
     <div class="mobileavatar" v-if="isMobile">
       <Avatar  :size="42" :did="pageValue.owner" :hash="pageValue.creatorPhoto" showAvatarInfo/>
    </div>
    
   
    <!-- <v-img  class="avatar"  style="border: solid 2px #ffffff; cursor: pointer;" :src="usersrc" @click="tocollectpage" /> -->
    <!-- <v-img v-if="otherpage" class="avatar" style="border: solid 2px #ffffff; cursor: pointer;" :src="usersrc" @click="tocollectpage" /> -->
    <slot></slot>
  </v-card>
</template>

<script>
import { getFileSrc } from "@/utils/file";
const WORK_KEY = "WORK";

const AVATAR_KEY = "AVATAR";
import defaultImg from "@/assets/default.png";
import Avatar from "@/components/avatar/index";

export default {
  components: {	Avatar	},
  props: {
    hash: {
      type: String,
    },
    pageValue: {
      type: Object,
    },
    showAvatar: {
      type: Boolean,
      default: false
    },
    otherpage: {
      type: Boolean,
      default: false
    },
    type:{
       type: String,
    },
     collectfrom: {
        type: String,
      
      },
      collectsource:{
         type: String,
      },
      did:{
         type: String,
      }
  },
  data: () => ({
    src: undefined,
    usersrc: "",
    defaultImg
  }),
  watch: {
    hash: {
      handler: async function (hash) {
        if (!hash) {
          hash = "QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
        }
        this.src = await getFileSrc(WORK_KEY, hash);
      },
      immediate: true,
    },
  },
	computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
	},
  async mounted() {
    console.log("wxl --- collectsource",this.collectsource,this.pageValue.creatorPhoto)

    if(this.pageValue.creatorPhoto != null  )
    {
      this.usersrc = await getFileSrc(AVATAR_KEY, this.pageValue.creatorPhoto);
    }else{
       this.usersrc = defaultImg;
    }
    	if(this.pageValue.listAmount){
    		this.pageValue.amount=this.pageValue.listAmount
    	}
  },
  methods: {
    tocollectpage(){
this.$router.push({
          name: "Otherpage",
          query: { did: this.pageValue.owner },
        });
    },
    imgClick() {
    
      let url
      if(this.pageValue.linkUrl)
      { 
       url = this.pageValue.linkUrl
      }else{
       url = this.pageValue.address 
      }
      console.log("wxl -- 123455",this.type,this.pageValue,this.$store.state.did)
      if (this.type == "createorPage" ) {
        // if(this.pageValue.owner == this.$store.state.did)
        // {
        //    this.$router.push({
        //      path: `/collection_create/${url}`,   
        // });
        // }else{
        //      this.$router.push({
        //      path: `/collection/${url}`,   
        // });
        // }
            this.$router.push({
             path: `/collection_create/${url}/${this.collectsource}/${this.did}`,   
        });
       
      }else{
        let owner;
        if(this.did){
          owner = this.did
        }else{
          owner = this.pageValue.owner
        }
        this.$router.push({
          
             path: `/collection/${url}/${this.collectsource}/${owner}`,   
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.v-card {
  width: 100%;
  height: 220px;
  background-color: #270645;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #270645;

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 220px;
  }

  &:hover {
    border: solid 2px #1d42ff;
  }

  .img {
    object-fit: cover;
    opacity: 0.85;
  }

  .name {
    position: absolute;
    font-family: Helvetica;font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    width: 150px;
    color: #ffffff;
    top: 18px;
    left: 18px;
    font-weight: bold !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .num {
    position: absolute;
    font-family: Helvetica;font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    top: 42px;
    left: 18px;
  }

  .avatar {
    position: absolute;
    width: 41px;
    height: 41px;
    border-radius: 50% !important;
    bottom: 20px;
    top: 150px;
    left: 90px;
  }
  .mobileavatar{
     position: absolute;
    width: 41px;
    height: 41px;
    border-radius: 50% !important;
    bottom: 20px;
    top: 150px;
    left: 145px;
  }
}
</style>